const { subapps } = require("@constants/enums")
const { _API_URL_, _API_TOUR_URL_ } = require("@env")
const { subapp } = require("@utils/functions")

const cLoc = typeof location !== "undefined" ? location : {}
const urlDev = _API_URL_.replace("https://", "")
const isLocal =
  cLoc.host &&
  (cLoc.host.startsWith("localhost") || cLoc.host === `${urlDev}:8000`)

switch (subapp) {
  case subapps.TOUR:
    remote = _API_TOUR_URL_
    break
  case subapps.HELP:
  default:
    remote = _API_URL_
    break
}

const _HYBREED_URL_ = isLocal ? remote : `${cLoc.protocol}//${cLoc.host}`

const endpoints = ({ email = "", password = "", token = "" } = {}) => {
  return {
    GET: {
      options: `${_HYBREED_URL_}/options/platform`,
      tenant: `${_HYBREED_URL_}/external/tenant/${token}`,
      room: `${_HYBREED_URL_}/video/room/${token}`,
    },
    POST: {
      auth: {
        login: `${_HYBREED_URL_}/auth/login`,
        refreshToken: `${_HYBREED_URL_}/auth/refresh-token`,
        resetPassword: `${_HYBREED_URL_}/auth/reset-password`,
      },
      query: `${_HYBREED_URL_}/admin/api`,
      paymentIntent: `${_HYBREED_URL_}/payment/create-intent`,
      otp_request: `${_HYBREED_URL_}/external/start-verification`,
      otp_compare: `${_HYBREED_URL_}/external/complete-verification`,
      otp_newRequest: `${_HYBREED_URL_}/external/resend-verification`,
      contact: `${_HYBREED_URL_}/external/create-request`,
      calendar_days: `${_HYBREED_URL_}/calendar/working-days`,
      timeslots: `${_HYBREED_URL_}/calendar/available-slots`,
      upload_document: `${_HYBREED_URL_}/video/room/upload`,
    },
    PUT: {},
    DELETE: {
      room: `${_HYBREED_URL_}/video/room/${token}`,
    },
  }
}

module.exports = { endpoints, _HYBREED_URL_ }
