import { initReactI18next } from "react-i18next"

import detectBrowserLanguage from "detect-browser-language"
import i18n from "i18next"

import store from "@store/"
import { getUserLanguage } from "@store/account/selectors"
import { getOptionsLanguage } from "@store/options/selectors"

import { languages, subapps } from "@constants/enums"

import { dayjsLocale } from "@utils/dayjsLocale"
import { getQueryParam } from "@utils/functions"

import de from "./de"
import gb from "./en"
import us from "./en"
import fr from "./fr"
import it from "./it"
import pl from "./pl"

let locales = {
  it,
  gb,
  us,
  fr,
  de,
  pl,
}

// TODO: remove when TOUR ready for prod
// start
const isTour = process.env.GATSBY_SUBAPP === subapps.TOUR
if (isTour) {
  locales.gb = locales.it
  locales.us = locales.it
  locales.fr = locales.it
  locales.de = locales.it
  locales.pl = locales.it
}
// end

const whitelist = Object.keys(locales)
const [fallbackLng] = whitelist
const resources = {}
whitelist.map(l => {
  resources[l] = { translation: locales[l] }
})

export const i18nInit = async setLang => {
  const lng = setLang || getCurrentLanguage() || fallbackLng

  return await i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // debug: true,
      whitelist,
      initImmediate: true,
      nonExplicitWhitelist: true,
      load: "languageOnly",
      lng,
      fallbackLng,

      resources,

      interpolation: {
        escapeValue: false,
      },
    })
}

export const changeLanguage = lng => {
  if (i18n.isInitialized) {
    i18n.changeLanguage(lng)
  } else {
    i18nInit()
  }
  dayjsLocale(null, lng)
}

export const getCurrentLanguage = () => {
  if (
    (typeof window !== "undefined" && window.navigator) ||
    typeof navigator !== "undefined"
  ) {
    const state = store.getState()
    const queryLng = getQueryParam("lng")
    const optLng = getOptionsLanguage(state)
    const userLng = getUserLanguage(state)
    const lng = queryLng || optLng || userLng

    const detectedLng = lng || detectBrowserLanguage().substring(0, 2) || null

    // Check if the redux language code exists in the mapped selectable languages
    return !!languages[detectedLng] ? detectedLng : fallbackLng
  } else {
    return null
  }
}
