export const statuses = {
  CALLING: "a_calling",
  WAITING: "b_waiting",
  LOST: "c_lost",
  CLOSED: "d_closed",
  CANCELLED: "e_cancelled",
  DELETED: "z_deleted",
}

export const timeStatuses = {
  CANCELLED: "cancelled",
  FINISHED: "finished",
  READY: "ready",
  WAITING: "waiting",
  EXPIRED: "expired",
}

export const creditCardBrands = {
  MASTERCARD: "mastercard",
  VISA: "visa",
  AMEX: "amex",
  DISCOVER: "discover",
  JCB: "jcb",
  DINERS: "diners",
  UNIONPAY: "unionPay",
}

export const filters = {
  ACTIVE: [statuses.WAITING, statuses.CALLING],
  SCHEDULED: [statuses.WAITING],
}

export const roles = {
  OPERATOR: "operator",
  MANAGER: "manager",
}

export const priorities = {
  HIGH: "a_high",
  MEDIUM: "b_medium",
  LOW: "c_low",
}

export const modes = {
  SCHEDULED: "scheduled",
  LIVE: "live",
}

export const tableModes = {
  ROWS: "rows",
  CARDS: "cards",
}

export const bandyerEvents = {
  STARTED_CALL: "bandyer:call:started",
  ENDED_CALL: "bandyer:call:ended",
  ENDED_BROADCAST: "bandyer:broadcast:exit",
}

export const entities = {
  OPERATOR: "operator",
  CLIENT: "client",
  TOPIC: "topic",
  REQUEST: "request",
  EVENT: "event",
  TOUR: "tour",
  CALENDAR: "calendar",
}

export const subapps = {
  HELP: "help",
  TOUR: "tour",
}

export const visibilities = {
  HIDDEN: "hidden",
  ACTIVE: "active",
  BLOCKED: "blocked",
  DELETED: "deleted",
}

export const languages = {
  it: { long: "Italiano", short: "ITA" },
  gb: { long: "English", short: "ENG" },
  fr: { long: "Français", short: "FRA" },
  de: { long: "Deutsch", short: "DEU" },
  pl: { long: "Polskie", short: "POL" },
}

export const avaiableCountryCodes = ["CH", "DE", "AT", "FR", "IT"]

export const weekdays = [
  1, // "monday"
  2, // "tuesday",
  3, // "wednesday",
  4, // "thursday",
  5, // "friday",
  6, // "saturday",
  7, // "sunday"
]
